import React from 'react'
import { Link } from 'react-router-dom'
import QuantityPicker from '../reusable/QuantityPicker'
import { FormattedMessage } from 'react-intl'

export default ({ cart, setCart, cartOpen, setCartOpen }) => {
  const handleQuantityChange = (ordertype, itemid, qty) => {
    setCart(prevCart => {
      let cartIndex = prevCart[ordertype].map(item => item.itemid).indexOf(itemid)
      let newCart = prevCart[ordertype]
      newCart[cartIndex].qty = qty
      if (qty === 0) {
        newCart.splice(cartIndex, 1)
        return {
          ...prevCart,
          [ordertype]: newCart
        }
      }
      else {
        return {
          ...prevCart,
          [ordertype]: newCart
        }
      }
    })
  }

  const handleSetQty = (newVal) => {
    return newVal
  }

  const removeItemFromCart = (ordertype, itemid) => {
    setCart(prevCart => {
      let cartIndex = prevCart[ordertype].map(item => item.itemid).indexOf(itemid)
      let newCart = prevCart[ordertype]
      newCart.splice(cartIndex, 1)
      return {
        ...prevCart,
        [ordertype]: newCart
      }
    })
  }

  return (
    <div className={`cart-menu menu-container container ${cartOpen ? "menu-open" : ""}`}>
      <div className="flex">
        <h1 className="page-title"><FormattedMessage id="shoppingcart" defaultMessage="Shopping Cart"/></h1>
        <button className="cart-close-btn btn-no-style relative" onClick={() => setCartOpen(false)}>
          <svg className="cart-menu-close zi-12 wht" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 327.43 327.43"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><line className="cls-1" x1="21.21" y1="21.21" x2="306.21" y2="306.21"/><line className="cls-1" x1="306.21" y1="21.21" x2="21.21" y2="306.21"/></g></g></svg>
          <div className="whiteout relative zi-11 wht"></div>
          <p className="cart-undertext zi-10"><FormattedMessage id="closemenu" defaultMessage="Close"/></p>
        </button>
      </div>
      <p className="cart-menu-big-p mb-6 mt-0"><FormattedMessage id="cart.subheader.1" defaultMessage="Please check your items and their corresponding quantities.&nbsp;&nbsp;When you're finished, scroll down to the bottom and click "/><b><FormattedMessage id="button.summary" defaultMessage="Continue to Summary"/></b><FormattedMessage id="cart.subheader.2" defaultMessage=".&nbsp;&nbsp;Alternatively, you can click the cart towards the top right corner to close this menu and return to where you were at."/></p>
      <h3><FormattedMessage id="onetime" defaultMessage="One Time"/></h3>
      {
        cart[1] && cart[1].length > 0 
        ?
        <div className="cart-scrolling-container">
          {
            cart[1].map((item, i) => (
              <div key={i} className="cart-product-container">
                <div className="cart-product-split">
                  <img className="cart-product-image" src={`https://truvision.corpadmin.directscale.com/CMS/Images/Inventory/${item.image}`} alt="product in cart"/>
                  <p>{item.productname.slice(0, 14)}...</p>
                </div>
                <div className="cart-product-split">
                  <QuantityPicker
                    qty={item.qty}
                    setQty={handleSetQty((newQty) => handleQuantityChange(1, item.itemid, newQty))}
                  />
                  <button onClick={() => removeItemFromCart(1, item.itemid)} className="cart-remove-product"><FormattedMessage id="remove" defaultMessage="Remove"/></button>
                </div>
              </div>
            ))
          }
        </div>
        :
        <p><FormattedMessage id="cart.nothing.onetime" defaultMessage="Nothing in your One Time cart."/></p>
      }
      <h3><FormattedMessage id="subscription" defaultMessage="Subscription"/></h3>
      {
        cart[2] && cart[2].length > 0 
        ?
        <div className="cart-scrolling-container">
          {
            cart[2].map((item, i) => (
              <div key={i} className="cart-product-container">
                <div className="cart-product-split">
                  <img className="cart-product-image" src={`https://truvision.corpadmin.directscale.com/CMS/Images/Inventory/${item.image}`} alt="product in cart"/>
                  <p>{item.productname.slice(0, 14)}...</p>
                </div>
                <div className="cart-product-split">
                  <QuantityPicker
                    qty={item.qty}
                    setQty={handleSetQty((newQty) => handleQuantityChange(2, item.itemid, newQty))}
                  />
                  <button onClick={() => removeItemFromCart(2, item.itemid)} className="cart-remove-product"><FormattedMessage id="remove" defaultMessage="Remove"/></button>
                </div>
              </div>
            ))
          }
        </div>
        :
        <p><FormattedMessage id="cart.nothing.subscription" defaultMessage="Nothing in your Subscription cart."/></p>
      }
      <div className="flex-btn">
        <Link onClick={() => setCartOpen(false)} className="btn primary-btn cart-summary-btn continue-btn mw-800" to="/summary"><FormattedMessage id="button.summary" defaultMessage="Continue to Summary"/></Link>
      </div>
    </div>
  )
}