import React from 'react'
import '../../styles/snackbar.css'

export default ({setShowSnackbar, title = "Success", message = "Successfully did the thing!", bgColor = "#2DB57E", fontColor = "#FFFFFF", timeToLeave = 3000}) => {
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setShowSnackbar(null)
    }, timeToLeave)

    return () => clearTimeout(timeout)
  }, [message])

  return (
    <div style={{backgroundColor: bgColor}} className="snackbar-container">
      <div className="snackbar-flex">
        <div style={{color: fontColor}} className="snackbar-title">{title}</div>
        <button onClick={() => setShowSnackbar(null)}>
          <svg style={{stroke: fontColor, opacity: 0.8}} className="snackbar-close" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 327.43 327.43"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><line className="cls-1" x1="21.21" y1="21.21" x2="306.21" y2="306.21"/><line className="cls-1" x1="306.21" y1="21.21" x2="21.21" y2="306.21"/></g></g></svg>
        </button>
      </div>
      <div style={{color: fontColor, opacity: 0.8}} className="snackbar-message">{message}</div>
    </div>
  )
}