import React from 'react'
import Fade from 'react-reveal/Fade'
import { Link } from 'react-router-dom'

export default ({ closeMenus, menuOpen, setCart, selectedLanguage = 'en' }) => {
  const menuItems = {
    en:
    [
      {
        title: 'Restart',
        link: '/',
        onClick: () => setCart({1: [], 2: []})
      },
      {
        title: 'Create a New Sharing Link',
        link: '/enroll'
      },
      {
        title: 'My Back Office',
        link: 'https://office2.truvy.com/app.html#/Home'
      },
      {
        title: 'Import or Test an Existing Link',
        link: '/import'
      },
      {
        title: 'How to Use Tools',
        link: '/#scTutorial'
      },
      {
        title: 'Contact',
        link: '/contact'
      }
    ],
    es:
    [
      {
        title: 'Reiniciar',
        link: '/',
        onClick: () => setCart({1: [], 2: []})
      },
      {
        title: 'Crear un Nuevo Enlace para Compartir',
        link: '/enroll'
      },
      {
        title: 'Mi Back Office',
        link: 'https://office2.truvy.com/app.html#/Home'
      },
      {
        title: 'Importar o Probar Enlace Existente',
        link: '/import'
      },
      {
        title: 'Cómo usar Tools',
        link: '/#scTutorial'
      },
      {
        title: 'Contacto',
        link: '/contact'
      }
    ]
  }

  const handleMultiOnClick = (fn1, fn2) => {
    fn1()
    fn2()
  }

  const [ classAdded, setClassAdded ] = React.useState(false)
  const [ isUnMounting, setIsUnmounting ] = React.useState(false)

  let addClass
  let unMount

  React.useEffect(() => {
    if (menuOpen === true) {
      addClass = setTimeout(() => {
        setClassAdded(true)
      }, 10)
    }
    
    else {
      setClassAdded(false)
      setIsUnmounting(true)
      unMount = setTimeout(() => {
        setIsUnmounting(false)
      }, 200)
    }

    return () => {
      if (menuOpen === false) {
        setIsUnmounting(true)
      }
      clearTimeout(addClass)
      clearTimeout(unMount)
    }
  }, [menuOpen])

  return (
    menuOpen || isUnMounting ?
    <div className={`menu-container ${classAdded ? 'menu-open' : ''}`}>
      <Fade top cascade>
        <ul>
          {
            menuItems[selectedLanguage].map(item => {
              if (item.link.indexOf('#') > -1) {
                return (
                  <a href={item.link} key={item.title} onClick={closeMenus}>
                    <li>
                      {item.title}
                    </li>
                  </a>
                )
              }
              else {
                return (
                  <Link to={item.link} key={item.title} onClick={item.onClick ? () => handleMultiOnClick(closeMenus, item.onClick) : closeMenus}>
                    <li>
                      {item.title}
                    </li>
                  </Link>
                )
              }
            })
          }
        </ul>
        </Fade>
    </div>
    : null
  )
}