import React, { lazy, Suspense } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'

import { CookiesProvider, useCookies } from 'react-cookie'
import { IntlProvider } from 'react-intl'
import { configureAnchors } from 'react-scrollable-anchor'

import messages_en from './locales/en.json'
import messages_es from './locales/es.json'

import Loading from './components/reusable/Loading'
import Nav from './components/Nav'
import Footer from './components/Footer'
import Snackbar from './components/reusable/Snackbar'

import { fetchPost, cleanCountriesArray } from './functions'

import './App.css'

const Home = lazy(() => import('./components/Home'))
const Enroll = lazy(() => import('./components/Enroll'))
const Shop = lazy(() => import('./components/Shop/Shop'))
const Product = lazy(() => import('./components/Shop/Product'))
const Summary = lazy(() => import('./components/Summary'))
const Generate = lazy(() => import('./components/Generate'))
const Import = lazy(() => import('./components/Import'))
const Privacy = lazy(() => import('./components/Privacy'))
const Contact = lazy(() => import('./components/Contact'))
const Thanks = lazy(() => import('./components/Contact/Thanks'))
const Four = lazy(() => import('./components/404'))


const messages = {
  'en': messages_en,
  'es': messages_es
}

const pagesToHideCart = [
  "/summary",
  "/generate",
  "/import"
]

const baseLangOptions = [{ value: 'en', label: 'English' }, { value: 'es', label: 'Español' }]
const baseLangOps = baseLangOptions.map(op => op.value)

const languageAvailability = {
  en: '*',
  es: [
    "US",
    "BM",
    "CA",
    "CO",
    "CR",
    "SV",
    "GR",
    "GT",
    "HN",
    "IE",
    "MX",
    "PA",
    "PE"
  ]
}

const languageDefaults = {
  "PA": "es",
  "CR": "es",
  "SV": "es",
  "GT": "es",
  "HN": "es",
  "MX": "es",
  "PE": "es",
  "CO": "es",
  "US": "en"
}


configureAnchors({ offset: -85, scrollDuration: 500 })


export default function App() {
  const location = useLocation()

  const [showSnackbar, setShowSnackbar] = React.useState(null)
  const [cookies, setCookie] = useCookies(['tvtlang', 'tvtusername', 'tvtcountry'])
  const [langOptions, setLangOptions] = React.useState(baseLangOptions)
  const [selectedLanguage, setSelectedLanguage] = React.useState(cookies.tvtlang ? cookies.tvtlang : langOptions.map(lang => lang.value).indexOf(navigator.language.split(/[-_]/)[0]) > -1 ? navigator.language.split(/[-_]/)[0] : 'en')
  const [enrolleeSelectedLanguage, setEnrolleeSelectedLanguage] = React.useState(cookies.tvtlang ? cookies.tvtlang : langOptions.map(lang => lang.value).indexOf(navigator.language.split(/[-_]/)[0]) > -1 ? navigator.language.split(/[-_]/)[0] : 'en')

  const [countries, setCountries] = React.useState(null)
  const [selectedCountry, setSelectedCountry] = React.useState(null)

  const [username, setUsername] = React.useState('')

  const [cart, setCart] = React.useState(JSON.parse(localStorage.getItem('tvtCart')) && JSON.parse(localStorage.getItem('tvtCart')).cart && JSON.parse(localStorage.getItem('tvtCart')).expiry > new Date() ? JSON.parse(localStorage.getItem('tvtCart')).cart : { 1: [], 2: [] })

  const [products, setProducts] = React.useState({ 0: { products: [], page: 0, noMoreProducts: false, disableNewProducts: false } })
  const [categories, setCategories] = React.useState([{ categoryid: 0, categoryname: "All Products", sortorder: 0 }])
  const [selectedCategory, setSelectedCategory] = React.useState(categories[0])

  const handleUsernameOnChange = (e) => {
    if (/^[a-z0-9]+$/i.test(e.target.value) || e.target.value === "") {
      setUsername(e.target.value)
    }
  }


  const [menuOpen, setMenuOpen] = React.useState(false)
  const [cartOpen, setCartOpen] = React.useState(false)
  const [showModal, setShowModal] = React.useState(false)

  const changeBunchaStuff = (un, cc, ct, lg) => {
    setUsername(un)
    setSelectedCountry(cc)
    setCart(ct)
    setEnrolleeSelectedLanguage(lg)
  }

  React.useEffect(() => {
    if (menuOpen || cartOpen || showModal) {
      document.body.classList.add('no-scroll')
    }

    else {
      document.body.classList.remove('no-scroll')
    }
  }, [menuOpen, cartOpen, showModal])

  React.useEffect(() => {
    if (cart && typeof cart === "object" && cart[1].length >= 0 && cart[2].length >= 0) {
      localStorage.setItem('tvtCart', JSON.stringify({ expiry: new Date().setDate(new Date().getDate() + 2), cart }))
    }

    else {
      setShowSnackbar({ title: "Something went wrong!", message: `Something went wrong with the cart, resetting...`, bgColor: "#FC767D" })
      setCart({ 1: [], 2: [] })
    }
  }, [cart])

  React.useEffect(() => {
    fetchPost('/api/getCountries', { languageCode: selectedLanguage })
      .then(countries => {
        let cleanCountries = cleanCountriesArray(countries)
        setCountries(cleanCountries)
      })
      .catch(err => console.log(err))
  }, [selectedLanguage])

  React.useEffect(() => {
    if (countries) {
      let cookieIndex = countries.map(country => country.value).indexOf(cookies.tvtcountry)
      setSelectedCountry(cookieIndex > -1 ? countries[cookieIndex] : countries[0])
    }
  }, [countries])

  React.useEffect(() => {
    if (selectedCountry) {
      let languagesAvailable = []
      for (let key in languageAvailability) {
        if (languageAvailability[key] === '*' || languageAvailability[key].indexOf(selectedCountry.code) > -1) {
          languagesAvailable.push(baseLangOptions[baseLangOps.indexOf(key)])
        }
      }
      setLangOptions(languagesAvailable)

      if (languageDefaults[selectedCountry.code]) {
        setEnrolleeSelectedLanguage(languageDefaults[selectedCountry.code])
      }
      setCountryCookie(selectedCountry.value)
    }
  }, [selectedCountry])

  React.useEffect(() => {
    if (selectedCountry && languageAvailability[enrolleeSelectedLanguage] !== "*" && languageAvailability[enrolleeSelectedLanguage].indexOf(selectedCountry.code) === -1) {
      setEnrolleeSelectedLanguage('en')
    }
  }, [enrolleeSelectedLanguage, selectedCountry])

  const setCountryCookie = () => {
    setCookie('tvtcountry', selectedCountry.value ? selectedCountry.value : 'US', { path: '/', expires: new Date('1/1/2099') })
  }

  React.useEffect(() => {
    if (selectedCountry) {
      setProducts({ 0: { products: [], page: 0, noMoreProducts: false, disableNewProducts: false } })
      setCategories([{ categoryid: 0, categoryname: "All Products", sortorder: 0 }])
      setSelectedCategory(categories[0])
      if (selectedCountry.value !== cookies.tvtcountry) {
        setCart({ 1: [], 2: [] })
      }
    }
  }, [selectedCountry])


  React.useEffect(() => {
    if (cookies.tvtlang) {
      setSelectedLanguage(cookies.tvtlang)
    }
    else {
      setLangCookie()
    }
  }, [])

  React.useEffect(() => {
    setLangCookie()
  }, [selectedLanguage])

  const setLangCookie = () => {
    setCookie('tvtlang', selectedLanguage ? selectedLanguage : 'en', { path: '/', expires: new Date('1/1/2099') })
  }


  React.useEffect(() => {
    if (cookies.tvtusername) {
      setUsername(cookies.tvtusername)
    }

    else {
      setUsernameCookie()
    }
  }, [])

  React.useEffect(() => {
    setUsernameCookie()
  }, [username])

  const setUsernameCookie = () => {
    setCookie('tvtusername', username ? username.substr(0, 60) : '', { path: '/', expires: new Date('1/1/2099') })
  }

  return (
    <CookiesProvider>
      <IntlProvider locale={selectedLanguage} messages={messages[selectedLanguage]}>
        <div id="outside-container">
          <Nav
            hideCart={pagesToHideCart.indexOf(location.pathname) > -1}
            cart={cart}
            setCart={setCart}
            cartOpen={cartOpen}
            setCartOpen={setCartOpen}
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            selectedLanguage={selectedLanguage}
          />
          {showSnackbar && <Snackbar setShowSnackbar={setShowSnackbar} title={showSnackbar.title} message={showSnackbar.message} bgColor={showSnackbar.bgColor} fontColor={showSnackbar.fontColor} timeToLeave={showSnackbar.timeToLeave} />}
          <div className="pt-85">
            <Suspense fallback={<Loading text={"Loading page..."} />} >
              <Switch>
                <Route exact path="/" render={() => <Home selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />} />
                <Route exact path="/enroll" render={() => (
                  <Enroll
                    username={username}
                    handleUsernameOnChange={handleUsernameOnChange}
                    countries={countries}
                    selectedCountry={selectedCountry}
                    setSelectedCountry={setSelectedCountry}
                    enrolleeSelectedLanguage={enrolleeSelectedLanguage}
                    setEnrolleeSelectedLanguage={setEnrolleeSelectedLanguage}
                    langOptions={langOptions}
                  />
                )} />
                <Route exact path="/shop" render={() => (
                  <Shop
                    products={products}
                    setProducts={setProducts}
                    categories={categories}
                    setCategories={setCategories}
                    username={username}
                    selectedCountry={selectedCountry}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                    selectedLanguage={selectedLanguage}
                  />
                )} />
                <Route path="/product" render={() => (
                  <Product
                    cart={cart}
                    setCart={setCart}
                    setShowSnackbar={setShowSnackbar}
                    products={products}
                    selectedLanguage={selectedLanguage}
                  />
                )} />
                <Route exact path="/summary" render={() => (
                  <Summary
                    showModal={showModal}
                    setShowModal={setShowModal}
                    username={username}
                    handleUsernameOnChange={handleUsernameOnChange}
                    countries={countries}
                    selectedCountry={selectedCountry}
                    setSelectedCountry={setSelectedCountry}
                    cart={cart}
                    setCart={setCart}
                    enrolleeSelectedLanguage={enrolleeSelectedLanguage}
                    setEnrolleeSelectedLanguage={setEnrolleeSelectedLanguage}
                    langOptions={langOptions}
                  />
                )} />
                <Route exact path="/generate" render={() => (
                  <Generate
                    username={username}
                    selectedCountry={selectedCountry}
                    enrolleeSelectedLanguage={enrolleeSelectedLanguage}
                    cart={cart}
                    setCart={setCart}
                    setShowSnackbar={setShowSnackbar}
                  />
                )} />
                <Route exact path="/import" render={() => (
                  <Import
                    changeBunchaStuff={changeBunchaStuff}
                    setShowSnackbar={setShowSnackbar}
                    countries={countries}
                    selectedLanguage={selectedLanguage}
                  />
                )} />
                <Route exact path="/privacy" render={() => <Privacy />} />
                <Route exact path="/contact" render={() => <Contact setShowSnackbar={setShowSnackbar} selectedLanguage={selectedLanguage} />} />
                <Route exact path="/contact/thankyou" render={() => <Thanks />} />

                <Route render={() => <Four />} />
              </Switch>
            </Suspense>
          </div>
          <Footer />
        </div>
      </IntlProvider>
    </CookiesProvider>
  );
}