const regions = {
  US: 1,
  GB: 2,
  NZ: 3,
  MX: 4,
  CA: 5,
  AU: 6,
  ZA: 7,
  DE: 8,
  CR: 27,
  IE: 28,
  PA: 29,
  GT: 31,
  CO: 32,
  HN: 35,
  NA: 36,
  RO: 38,
  AE: 40
}

const categories = {
  3: "More",
  13: "Experience Kits",
  26: "Branded",
  27: "Promotional",
  65: "Canada Products",
  68: "Pre-Order",
  69: "Best Sellers",
  70: "Tru+",
  71: "Energy & Weight Loss",
  72: "Liquid Energy & Weight Loss",
  73: "Hydration",
  75: "Cellular Nutrition(Vitamins)",
  74: "Gut Health" ,
  76: "Protien",
  77: "Brain",
  78: "Relief",
  79: "Hair, Skin, Nails",
  81: "Core 4",
  82: "Last Chance"
}

let categorySortOrder = [69, 81, 27, 65, 71, 72, 73, 75, 68, 74, 70, 76, 77, 79, 13, 3, 82, 26]

export function fetchGet(url, body) {
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
  .then(res => res.json())
}

export function fetchPost(url, body) {
  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
  .then(res => res.json())
}

export function cleanCountriesArray(countries) {
  countries = countries.map(country => {
    return {
      value: country.countrycodename,
      label: country.countryname,
      code: country.countrycode
    }
  })
  return countries
}

export function getAllProducts(countryCode, languageCode) {
  return fetchPost('/api/getAllProducts', { region: regions[countryCode], languageCode })
}

export function getProducts(countryCode, page, categoryid, searchQuery = "", languageCode) {
  return fetchPost('/api/getProducts', { region: regions[countryCode], page, categoryid, searchQuery, languageCode })
}

export function getCategories(countryCode) {
  return fetchPost('/api/getCategories', { region: regions[countryCode] })
}

export function cleanCategories(cats) {
  let newCatArr = [{categoryid: 0, categoryname: "All Products", sortorder: 0}, {categoryid: "search", categoryname: "Search", sortorder: 0}]
  cats.map(cat => cat.categoryid).forEach(id => {
    if (categories[id]) {
      newCatArr.push({categoryid: id, categoryname: categories[id], sortorder: categorySortOrder.indexOf(id) > -1 ? categorySortOrder.indexOf(id) + 1 : 100})
    }
  })
  return newCatArr.sort((a, b) => a.sortorder - b.sortorder)
}
