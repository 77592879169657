import React from 'react'
import { Link } from 'react-router-dom'
import Logo from './reusable/Logo'
import { FormattedMessage } from 'react-intl'

export default () => {
  return (
    <footer className="container footer-container">
      <div>
        <Link to="/contact"><FormattedMessage id="footer.contact" defaultMessage="Contact Us"/></Link>
        <Link to="/privacy"><FormattedMessage id="footer.privacy" defaultMessage="Privacy Policy"/></Link>
        <div className="copyright">© {new Date().getFullYear()} TruVision Health, LLC</div>
      </div>
      <div>
        <a className="flex m0" href='https://truvy.com'>
          <Logo />
        </a>
      </div>
    </footer>
  )
}