import React from 'react'
import Menu from './Nav/Menu'
import Cart from './Nav/Cart'
import { Link } from 'react-router-dom'
import Logo from './reusable/Logo'
import '../styles/hamburgers.css'

export default ({ cart, setCart, cartOpen, setCartOpen, menuOpen, setMenuOpen, hideCart, selectedLanguage }) => {
  const [ numItemsInCart, setNumItemsInCart ] = React.useState(0)
  const [ postPop, setPostPop ] = React.useState(false)
  const headerRef = React.useRef(null)

  const toggleMenuOpen = () => {
    setCartOpen(false)
    setMenuOpen(() => !menuOpen)
  }

  const toggleCartOpen = () => {
    setMenuOpen(false)
    setCartOpen(() => !cartOpen)
  }

  const closeMenus = () => {
    setCartOpen(false)
    setMenuOpen(false)
  }

  const handleCloseMenus = (e) => {
    if (headerRef.current && !headerRef.current.contains(e.target)) {
      closeMenus()
    }
  }

  React.useEffect(() => {
    document.addEventListener('mousedown', handleCloseMenus);
  })

  React.useEffect(() => {
    setNumItemsInCart((cart[1] && cart[1].length ? cart[1].map(item => item.qty).reduce((a, b) => a + b) : 0) + (cart[2] && cart[2].length ? cart[2].map(item => item.qty).reduce((a, b) => a + b) : 0))
  }, [cart])

  React.useEffect(() => {
    setPostPop(true)

    const timeout = setTimeout(() => {
      setPostPop(false)
    }, 200)

    return () => {
      setPostPop(false)
      clearTimeout(timeout)
    }
  }, [numItemsInCart])

  const [ classAdded, setClassAdded ] = React.useState(false)
  const [ isUnMounting, setIsUnmounting ] = React.useState(false)

  let addClass
  let unMount

  React.useEffect(() => {
    if (menuOpen || cartOpen) {
      addClass = setTimeout(() => {
        setClassAdded(true)
      }, 10)
    }
    
    else {
      setClassAdded(false)
      setIsUnmounting(true)
      unMount = setTimeout(() => {
        setIsUnmounting(false)
      }, 200)
    }

    return () => {
      if (!menuOpen || !cartOpen) {
        setIsUnmounting(true)
      }
      clearTimeout(addClass)
      clearTimeout(unMount)
    }
  }, [menuOpen, cartOpen])


  return (
    <React.Fragment>
      <header ref={headerRef} id="header">
        <nav>
          <Link onClick={() => closeMenus()} className="flex" to="/">
            <Logo />
          </Link>
          <div>
            {
              !hideCart &&
              <button onClick={toggleCartOpen} id="shopping-cart" className={`relative ${cartOpen ? "active-shopping-cart" : ""}`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 688.5 619.36"><title>Shopping Cart</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><polyline className="shopsvg-1" points="0 25 73.72 25 161.24 410.06 605 410.06"/><polygon className="shopsvg-2" points="138.83 311.47 593.16 311.47 655.86 79.02 86.43 79.02 138.83 311.47"/><circle className="shopsvg-2" cx="228.9" cy="537.97" r="36"/><circle className="shopsvg-2" cx="527.29" cy="537.97" r="36"/></g></g></svg>
                {
                  (cart[1] && cart[1].length) || (cart[2] && cart[2].length)
                  ?
                  <div id="cart-qty-container" className={postPop ? "postpoppin" : ""}>
                    <p>{numItemsInCart}</p>
                  </div>
                  :
                  null
                }
              </button>
            }
            <button className={`hamburger hamburger--slider ${menuOpen ? "is-active" : ""}`} type="button" onClick={toggleMenuOpen} onKeyDown={toggleMenuOpen}>
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </div>
        </nav>
          <Menu menuOpen={menuOpen} closeMenus={closeMenus} setCart={setCart} selectedLanguage={selectedLanguage}/>
          <Cart cartOpen={cartOpen} setCartOpen={setCartOpen} cart={cart} setCart={setCart}/>
      </header>

      {
        menuOpen || cartOpen || isUnMounting ?
        <div className={`fade ${classAdded ? "fon" : ""}`}></div>
        :
        null
      }
    </React.Fragment>
  )
}