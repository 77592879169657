import React from 'react'
import { Link } from 'react-router-dom'
import '../../styles/loading.css'
import { FormattedMessage } from 'react-intl'

export default ({ text }) => {
  const [ longLoading, setLongLoading ] = React.useState(false)

  React.useEffect(() => {
    const tenSec = setTimeout(() => {
      setLongLoading(true)
    }, 10000)

    return () => clearTimeout(tenSec)
  }, [])

  return (
    <div id="loading">
      <div className="sk-cube-grid">
        <div className="sk-cube sk-cube1"></div>
        <div className="sk-cube sk-cube2"></div>
        <div className="sk-cube sk-cube3"></div>
        <div className="sk-cube sk-cube4"></div>
        <div className="sk-cube sk-cube5"></div>
        <div className="sk-cube sk-cube6"></div>
        <div className="sk-cube sk-cube7"></div>
        <div className="sk-cube sk-cube8"></div>
        <div className="sk-cube sk-cube9"></div>
      </div>

      <div className="loading-text-container">
        <p id="loading-text">{text}</p>
        {
          longLoading &&
          <p className="lg"><FormattedMessage id="longload.1" defaultMessage="Loading taking a long time?"/><br /><FormattedMessage id="longload.2" defaultMessage="Try checking your connection, refreshing the page, or check back later."/><br /><FormattedMessage id="longload.3" defaultMessage="If the problem persists, you can reach out to us "/><b><Link to='/contact'><FormattedMessage id="here" defaultMessage="here"/></Link></b>.</p>
        }
      </div>
    </div>
  )
}