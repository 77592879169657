import React from 'react'
import plusSVG from '../../assets/img/plus.svg'
import minusSVG from '../../assets/img/minus.svg'

export default ({ disabled, qty, setQty}) => {
  const handleChange = (val) => {
    if (!isNaN(val) && val >= 0) {
      setQty(val)
    }
  }

  return (
    <div className="quantity-picker-container">
      <div className="quantity-picker">
        <button onClick={() => handleChange(qty - 1)} className="icon-btn">
          <div className="icon-container">
            <img draggable={false} className="icon minus-icon" src={minusSVG}/>
          </div>
        </button>
        <input value={qty} onChange={(e) => handleChange(+e.target.value)}/>
        <button onClick={() => handleChange(qty + 1)} className="icon-btn">
          <div className="icon-container">
            <img draggable={false} className="icon minus-icon" src={plusSVG}/>
          </div>
        </button>
      </div>
    </div>
  )
}